import React, { useContext } from "react";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import numeral from "numeral";
import { usePolicyContext } from "../../../../clientServicing/Payments/PolicyContext";
import { useActions } from "../../../../../actions";
import * as PaymentActions from "../../../../../actions/payment";
import moment from "moment";
import { fieldsName, paymentMethodsType } from "./utils";
import FormFields from "./FormFields";
import { QcPopUpContext } from "./QCPopUp";


export interface QCPopUpContentProps {
    client?: any
    children?:React.ReactNode,
}


export default function QCPopUpContent(props: QCPopUpContentProps) {

    const { children } = props
    const {client, enrollmentId, setOpenPopup, refreshList, setSubmitting} = useContext(QcPopUpContext)
    const clientName = `${client && client.user.firstName} ${client && client.user.lastName}`
    const {policy, refetchPolicy}: any = usePolicyContext();
    const {updateQCActionItem} = useActions(PaymentActions);
    const {createPayment, createPaymentSchedule} = useActions(PaymentActions);
    const paymentFrequency: string = client && client.clientPolicies && client.clientPolicies[0] && client.clientPolicies[0].paymentFrequency || "annual";
    const initialValues = {[fieldsName.trancheNo]:""};
    const submitFormik = async (values: any, actions: any) => {
        const submitting =(value)=>{
            setSubmitting && setSubmitting(value)
            actions.setSubmitting(value);
        }
        const timeTable: any = paymentFrequency === "annual" ? policy.paymentTimetableAnnual : policy.paymentTimetableMonthly;
        const newPaymentAmount = timeTable.nextPayment.amount && (timeTable.nextPayment.amount[0].amount + timeTable.nextPayment.amount[1].amount + timeTable.nextPayment.amount[2].amount);
        const newRecurringAmount = timeTable.recurringPayments && timeTable.recurringPayments[0].amount && (timeTable.recurringPayments[0].amount[0].amount + timeTable.recurringPayments[0].amount[1].amount + timeTable.recurringPayments[0].amount[2].amount);
        let paymentMethodId = values.paymentMethodId;
        submitting(true)
        //Check if payment method added
        if (paymentMethodId) {
            let paymentPayload: any = {
                policyNumber: policy.policyNumber,
                paymentMethodId: paymentMethodId,
                payerName: clientName,
                payerEmail: client.user.email,
                callingFrom: "QC-ACTION-ITEMS",
                clientId: client.id
            };
            let policyDetails: any = {}
            if (paymentFrequency === "monthly") {
                paymentPayload.payerTrancheNo = values[fieldsName.trancheNo];
                paymentPayload.type = "MonthlyEnrollment";
                paymentPayload.emailPayload = {
                    amount: numeral(newPaymentAmount).format("$0,0.00"),
                    recurringAmount: numeral(newRecurringAmount).format("$0,0.00"),
                    dueDate: timeTable && timeTable.recurringPayments && moment(timeTable.recurringPayments[0].date).format("MM/DD/YYYY") || "",
                };
                policyDetails = await createPaymentSchedule(paymentPayload, policy, true);
            } else {
                //Done need schedule in case of annual payment NIWD-251
                paymentPayload.clientPayment = {
                    date: moment().format("YYYY-MM-DD"),
                    type: "ePay",
                    details: "First payment for annual enrollment",
                    processedBy: clientName,
                    amount: timeTable.nextPayment.amount
                };
                policyDetails = await createPayment(paymentPayload);
            }

            if (!!policyDetails.policyNumber) {
                // moved to backend
                // await updateQCActionItem({status:1, type:"approve", userId: client.userId})
                await refetchPolicy();
                refreshList && await refreshList();
            }
            setOpenPopup && setOpenPopup(false)
        }
        submitting(false);
    }

    const validationSchema = Yup.object({
        [fieldsName.trancheNo] : Yup.string().required('The tranche number is required').nullable()
    })

    return   <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={validationSchema}
                validateOnChange={true}
                onSubmit={submitFormik}
    >
        {({
              handleSubmit,
              setFieldValue,
              isSubmitting,
              dirty,values, validateForm
          }:any) => {
            return (<form id={"QCEpayForm"} onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(e);
            }}>
                <Initializer />
                <p>The following eACh payment has been initiated by {clientName}. Review the payment details and Reject or Accept</p>
                <FormFields/>
                {children}
            </form>);
        }
        }
    </Formik>
};



const Initializer = (props: {}) => {
    const {policy, refetchPolicy}: any = usePolicyContext();
    const {client, enrollmentId} = useContext(QcPopUpContext)
    const paymentMethod : undefined |paymentMethodsType = (policy && policy.paymentMethods && policy.paymentMethods.length > 0) && policy.paymentMethods[0]
    const { setValues } = useFormikContext<any>()
    const paymentFrequency: string = client && client.clientPolicies && client.clientPolicies[0] && client.clientPolicies[0].paymentFrequency || "annual";
    const tranche = client && client.tranche || {};
    const formatedTrancheValue =(trancheNumber) =>{
        const trNO = trancheNumber + "";
        return {
            title: trNO,
            key: trNO,
            name: trNO,
            value: trNO,
        }
    }
    React.useEffect(()=>{
        if(paymentMethod){
            const timeTable = paymentFrequency === "annual" ? policy.paymentTimetableAnnual : policy.paymentTimetableMonthly;
            const newPaymentAmount = timeTable.nextPayment.amount && (timeTable.nextPayment.amount[0].amount + timeTable.nextPayment.amount[1].amount + timeTable.nextPayment.amount[2].amount);
            setValues({
                [fieldsName.accountNumber] : paymentMethod.maskedAccountNumber,
                [fieldsName.paymentMethod] : "ePay",
                [fieldsName.bankName] : paymentMethod.bankName,
                [fieldsName.amountPaid] : newPaymentAmount,
                [fieldsName.trancheNo] : tranche && formatedTrancheValue(tranche.trancheNumber),
                [fieldsName.bankAccHolder] : paymentMethod.clientName || "",
                [fieldsName.paymentMethodId] : policy.paymentMethods && policy.paymentMethods[0].recordKey || "",
                [fieldsName.checkFront] : policy.paymentMethods && policy.paymentMethods[0].files.find((name)=> name.includes("Frontofcheck")) || policy.paymentMethods[0].files[0],
                [fieldsName.checkBack] : policy.paymentMethods && policy.paymentMethods[0].files.find((name)=> name.includes("Backofcheck")) || policy.paymentMethods[0].files[0],

                // [fieldsName.] = timeTable && timeTable.recurringPayments && moment(timeTable.recurringPayments[0].date).format("MM/DD/YYYY") || "";

            })
        }
    },[paymentMethod, client])

   return <></>
}



