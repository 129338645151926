import React from "react";
import { Label } from "../../../../../components/formInputs";
import { ErrorCommon, TextField, TextFieldCommon, NumberTextField } from "../../../../../components/formikFormInputs";
import { Grid, Typography } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { restrictToTwoDecimals } from "../../../../../utils/commonUtils";

interface BankDetailsProps {
    values:any, handleChange:Function, errors:any, touched:any, disabled:boolean
}

const BankDetails = (props: BankDetailsProps) => {
    const { values, handleChange, errors, touched, disabled = false } = props;

    const fieldObj = [

        {
            name: "bankFinancedWith",
            label: <Label label={"Bank Name"}/>,
            isRequired: true,
            component: TextField,
            variant: "outlined",
            className: "fullWidthcustomFields",
            disabled: true
        },
            //Removed As Per NIWC-2690 Task
        // {
        //     id: "bankPremiumAmount",
        //     name: "bankPremiumAmount",
        //     label: <Label label={"Bank Premium Amount"}/>,
        //     component: NumberTextField,
        //     variant: "outlined",
        //     filedType: "number",
        //     className: "fullWidthcustomFields",
        //     InputProps: {
        //         startAdornment: <InputAdornment className="margin0 dollerIcon"
        //                                         position="start">$</InputAdornment>,
        //     },
        //     disabled
        // },
        {
            id: "arrangementFee",
            name: "arrangementFee",
            label: <Label label={"Arrangement Fee"}/>,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            disabled: true
        },
        {
            id: "bankMargin",
            name: "bankMargin",
            label: <Label label={"Bank Margin"}/>,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            className: "fullWidthcustomFields",
            allowMax100: true,
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">%</InputAdornment>,
            },
            disabled: true
        },
        {
            id: "niwMarginOverride",
            name: "niwMarginOverride",
            label: <Label label={"NIW Margin Override %"}/>,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            className: "fullWidthcustomFields",
            allowMax100: true,
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">%</InputAdornment>,
            },
            disabled: true
        },
    ];

    return (
            <React.Fragment>
                <Typography variant="subtitle1" component="strong" className="mb10 mt30 floatLeft w100">Bank
                    Details</Typography>
                <Grid container spacing={3}>
                    {fieldObj.map((field, index) => {
                        return (
                                <Grid item xs={12} md={6} xl={4}
                                      key={index} className="mb15">
                                    <TextFieldCommon
                                            {...field}
                                            values={values}
                                            onChange={(e) => {
                                                restrictToTwoDecimals(e);
                                                handleChange(e);
                                            }}
                                    />
                                    <ErrorCommon errors={errors} name={field.name}
                                                 touched={touched}/>
                                </Grid>
                        );
                    })
                    }

                </Grid>
            </React.Fragment>
    );

};
export default BankDetails;