
import {Location} from "history";

export const checkIsReviewDetailRoute = (location:Location<any>) =>{
        const reviewDetailsRoutes = ["/client/review-details", "/client/review-beneficiaries", "/client/edit-Personal-Details", "client/edit/legal-nam"];
        return reviewDetailsRoutes.includes(location.pathname)
}

export const personalDetailsFieldsName = {
    cellPhone: "cellPhone",
    workPhone: "workPhone",
    homePhone: "homePhone",
    email: "email",
    clientNickName: "clientNickName",
    dob: "dob",
    socialNumber: "socialNumber",
    age: "age",
    netWorth: "netWorth",
    annualIncome: "annualIncome",
    employer: "employer",
    homeAddress:"homeAddress",
    homeAddressCity:"homeAddressCity",
    homeAddressState:"homeAddressState",
    homeAddressZipCode:"homeAddressZipCode",
    mailingAddress:"mailingAddress",
    mailingCity:"mailingCity",
    mailingState:"mailingState",
    mailingZipCode:"mailingZipCode",
}