import React from "react";
import { Label } from "../../../../../components/formInputs";
import {
    ErrorCommon,
    TextFieldCommon,
    NumberTextField,
    NumberTextFieldWithCb
} from "../../../../../components/formikFormInputs";
import { Grid, Typography } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import numeral from "numeral";
import { restrictToTwoDecimals } from "../../../../../utils/commonUtils";

interface BenefitAmountProps {
    values:any, handleChange:Function, errors:any, touched:any, setFieldValue: Function, disabled:boolean
}

const BenefitAmount = (props: BenefitAmountProps) => {
    const { values, handleChange, errors, touched, setFieldValue, disabled = false } = props;

    React.useEffect(()=>{
        let val = numeral(values.initialTotalDeathBenefit).value();
        let termRiderDeathBenefitVal = numeral(values.termRiderDeathBenefit).value();
        let result = val - termRiderDeathBenefitVal
        setFieldValue("baseDeathBenefit", result || "")
    }, [])

    const fieldObj = [
        {
            id: "initialTotalDeathBenefit",
            name: "initialTotalDeathBenefit",
            label: <Label label={"Initial Total Death Benefit"}/>,
            placeholder: "Initial Total Death Benefit",
            isRequired: true,
            component: NumberTextFieldWithCb,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            cb: (e: any, setFieldValue, formValues) => {
                let val = numeral(e.target.value).value();
                let termRiderDeathBenefitVal = numeral(formValues.termRiderDeathBenefit).value();
                let result = val - termRiderDeathBenefitVal
                setFieldValue("baseDeathBenefit", result + "")
            },
            allowNegative: false,
            disabled
        },
        {
            id: "termRiderDeathBenefit",
            name: "termRiderDeathBenefit",
            label: <Label label={"Term Rider Death Benefit"}/>,
            isRequired: true,
            placeholder: "Term Rider Death Benefit",
            component: NumberTextFieldWithCb,
            className: "fullWidthcustomFields",
            cb: (e: any, setFieldValue, formValues) => {
                let val = numeral(e.target.value).value();
                let initialTotalDeathBenefitVal = numeral(formValues.initialTotalDeathBenefit).value();
                let result = initialTotalDeathBenefitVal - val
                setFieldValue("baseDeathBenefit", result)
            },
            allowNegative: false,
            disabled
        },
        {
            id: "baseDeathBenefit",
            name: "baseDeathBenefit",
            label: <Label label={"Base Death Benefit"}/>,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            helperText: "Calculated from initial minus term rider benefit.",
            disabled:true
        },
        {
            id: "targetAmount",
            name: "targetAmount",
            label: <Label label={"Target Amount"}/>,
            placeholder: "Target Amount",
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            allowNegative: false,
            disabled
        },
        {
            id: "incomeStartAge",
            name: "incomeStartAge",
            label: <Label label={"Potential Income at Age (minimum)"}/>,
            placeholder: "Potential Income at Age (minimum)",
            isRequired: true,
            component: NumberTextField,
            filedType: "number",
            allowMax100: true,
            variant: "outlined",
            className: "fullWidthcustomFields",
            disabled
        },

        {
            id: "incomeEndAge",
            name: "incomeEndAge",
            label: <Label label={"Potential Income at Age (maximum)"}/>,
            placeholder: "Potential Income at Age (maximum)",
            isRequired: true,
            component: NumberTextField,
            filedType: "number",
            allowMax100: true,
            variant: "outlined",
            className: "fullWidthcustomFields",
            disabled
        },

        {
            id: "estimatedAnnualIncome",
            name: "estimatedAnnualIncome",
            label: <Label label={"Estimated Annual Income"}/>,
            placeholder: "Estimated Annual Income",
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            helperText: "Based on start and end age.",
            disabled
        },
    ];

    return (
            <React.Fragment>
                <Typography variant="subtitle1" component="strong" className="mb10 mt30 floatLeft w100">Benefit
                    Amounts</Typography>
                <Grid container spacing={3}>
                    {fieldObj.map((field, index) => {
                        return (
                                <Grid item xs={12} md={6} xl={4}
                                      key={index}>
                                    <TextFieldCommon
                                            {...field}
                                            values={values}
                                            onChange={(e) => {
                                                restrictToTwoDecimals(e);
                                                handleChange(e);
                                            }}
                                    />
                                    <ErrorCommon errors={errors} name={field.name}
                                                 touched={touched}/>
                                </Grid>
                        );
                    })
                    }

                </Grid>
            </React.Fragment>
    );

};
export default BenefitAmount;