import React from "react";
import LoaderGif from "../../styles/images/loader_nw.gif";
import { getQueryStringValue } from "../../utils/queryString";

const DocusignComplete = () => {
    let searchParams = window.location.search;
    let event_status: any;
    if (searchParams) {
        event_status = getQueryStringValue("event", searchParams) || "";
    }
    React.useEffect(() => {
        if (event_status === "signing_complete") {
            // eslint-disable-next-line no-restricted-globals
            let backbtn: any = parent.document.getElementById("backbtn");
            backbtn &&  backbtn.click()
        }
    }, []);
    return (
            <div className="commonLoaderWrap textCenter">
                <img src={LoaderGif} alt='Loading...'/>
            </div>
    );
};
export default DocusignComplete;
