import React from "react";
import {useFormikContext} from "formik";
import {Grid, Typography} from "@material-ui/core";
import {
    ErrorCommon,
    NumberTextField,
    PhoneNumberField,
    SelectField,
    SSNNumberField,
    TextField,
    TextFieldCommon,
    TextFieldCommonFieldProps,
} from "../../../../../components/formikFormInputs";
import {Label} from "../../../../../components/formInputs";
import {FormikValues} from "../../../../../components/formikFormInputs";
import {stateDropDown} from "../../../../../constants";
import { enumAsArray, noop, restrictToTwoDecimals } from "../../../../../utils/commonUtils";


interface FieldType extends TextFieldCommonFieldProps {
    component: JSX.Element | any;
    name: string;
    variant?: string;
    label: JSX.Element;
}

enum RelationshipToInsured {
    Self = "Self",
    Spouse = "Spouse",
    Other = "Other"
}

enum Revocability {
    Revocable = "Revocable",
    Irrevocable = "Irrevocable"
}

const SettlorDetails = () => {
    const { errors, handleChange, touched, values }: FormikValues = useFormikContext();

    const relationshipToInsuredOpn = enumAsArray(RelationshipToInsured).map(key => ({ key, name: key }));
    const revocabilityOptions = enumAsArray(Revocability).map(key => ({ key, name: key }));
    const stateOpn = stateDropDown.map((state) => ({ key: state.value, name: state.name }));

    if (relationshipToInsuredOpn.find(r => r.name !== "Select")) {
        relationshipToInsuredOpn.unshift({ key: "", name: "Select" });
    }
    if (revocabilityOptions.find(r => r.name !== "Select")) {
        revocabilityOptions.unshift({ key: "", name: "Select" });
    }
    if (stateOpn.find(r => r.name !== "Select")) {
        stateOpn.unshift({ key: "", name: "Select" });
    }

    const basicFields: (FieldType | any)[] = [
        {
            component: NumberTextField,
            id: "providentAccountNo",
            label: <Label label={"Provident Account No."} />,
            name: "providentAccountNo",
        },
        {
            component: SelectField,
            label: <Label label={"Revocability"} />,
            name: "revocability",
            onSelectChange: noop,
            options: revocabilityOptions,
        },
        {
            component: SelectField,
            label: <Label label={"Settlor Relation to Insured"} />,
            name: "relationshipToInsured",
            onSelectChange: (val: string, setFieldValue: Function) => {
                if (val === 'Other') { // optional - can run without this condition
                    setFieldValue('otherRelation', '');
                }
            },
            options: relationshipToInsuredOpn,
        },
    ];
    const otherFields: (FieldType | any)[] = values && values["relationshipToInsured"] && values["relationshipToInsured"] === "Other" ? [
        {
            component: TextField,
            label: <Label label={"Other Relation"} />,
            name: "otherRelation",
        },
    ] : [];
    const personalAddressFields: (FieldType | any)[] = [
        {
            component: TextField,
            label: <Label label={"Settlor First Name"} />,
            name: "firstName",
        },
        {
            component: TextField,
            label: <Label label={"Middle Name"} />,
            name: "middleName",
        },
        {
            component: TextField,
            label: <Label label={"Last Name"} />,
            name: "lastName",
        },
        {
            component: TextField,
            label: <Label label={"Settlor Email Address"} />,
            name: "email",
        },
        {
            component: TextField,
            label: <Label label={"If Trust, Name"} />,
            name: "ifTrustName",
        },
        {
            component: SSNNumberField,
            id: "ssn",
            label: <Label label={"Settlor Social"} />,
            name: "ssn",
        },
        {
            component: PhoneNumberField,
            id: "phoneNumber",
            label: <Label label={"Settlor Phone Number"} />,
            name: "phoneNumber",
        }
    ];
    const settlorFields: (FieldType | any)[] = [...basicFields, ...otherFields, ...personalAddressFields];
    const settlorHomeAddressFields: (FieldType | any)[] = [
        {
            component: TextField,
            label: <Label label={"Settlor Address"} />,
            name: "streetAddress",
        },
        {
            component: TextField,
            label: <Label label={"Settlor City"} />,
            name: "city",
        },
        {
            component: SelectField,
            label: <Label label={"Settlor State"} />,
            name: "state",
            onSelectChange: noop,
            options: stateOpn,
        },
        {
            component: TextField,
            label: <Label label={"Settlor Zip"} />,
            name: "zip",
        },
    ];

    return (
            <>
                <Typography className="mb10 mt30 floatLeft w100" component="strong" variant="subtitle1">
                    Settlor Details
                </Typography>

                <Grid container spacing={3}>
                    {
                        settlorFields.map((f, index) => (
                                <Grid className="mb15" item key={index} md={6} xl={4} xs={12}>
                                    <TextFieldCommon
                                            {...f}
                                            values={values}
                                            onChange={(e) => {
                                                restrictToTwoDecimals(e);
                                                handleChange(e);
                                            }}
                                            variant="outlined"
                                    />
                                    <ErrorCommon
                                            errors={errors}
                                            name={f.name}
                                            touched={touched}
                                    />
                                </Grid>
                        ))
                    }
                </Grid>

                <Typography className="mb10 mt30 floatLeft w100" component="strong" variant="subtitle1">
                    Home Address
                </Typography>

                <Grid container spacing={3}>
                    {
                        settlorHomeAddressFields.map((f, index) => (
                                <Grid item key={index} md={6} xl={4} xs={12}>
                                    <TextFieldCommon
                                            {...f}
                                            values={values}
                                            onChange={handleChange}
                                            variant="outlined"
                                    />
                                    <ErrorCommon
                                            errors={errors}
                                            name={f.name}
                                            touched={touched}
                                    />
                                </Grid>
                        ))
                    }
                </Grid>
            </>
    );
};

export default SettlorDetails;
