import React from "react";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { useActions } from "../../actions";
import * as Actions from "../../actions/account";
import * as ClientActions from "../../actions/client";
import { ErrorCommon, NumberTextField, SelectField, TextFieldCommon } from "../formikFormInputs";
import { Formik } from "formik";
import { Label } from "../formInputs";
import { Button as FormikButton } from "../../components/formInputs";
import NumberFormat from "react-number-format";
import { unmaskCurrency } from "../../utils/commonUtils";
import { ActionItems as CurrentActionsItems, PaymentProcess } from "../../constants";
import {useDispatch, useSelector} from "react-redux";
import { RootState } from "../../reducers";
import * as PaymentActions from "../../actions/payment";
import * as TrancheActions from "../../actions/tranche";
import { Dialog as GenericDialog, GenericDialogProps } from "../dialog";
import { usePolicyContext } from "../clientServicing/Payments/PolicyContext";
import {ActionType} from "../../model";


export default function PaymentReceivedPopUp(props: { open: boolean, setPaymentReceivedPopUp: Function, enrollmentId: string, clientId: number, agentId: number, clientUserId: string, client: any, refreshList: Function, setFilterType?: Function, role?: number }) {


    const { open, setPaymentReceivedPopUp, enrollmentId, clientId, agentId, client, refreshList, setFilterType, role } = props;
    const [tranche, setTranche] = React.useState<any>([]);
    const auth: any = useSelector((state: RootState) => state.auth);
    const accountActions = useActions(Actions);
    const { processPayment } = useActions(PaymentActions);
    const clientActions = useActions(ClientActions);
    const trancheActions = useActions(TrancheActions);
    const listTrancheCount:number = 25;
    const { policy }: any = usePolicyContext();
    const dispatch = useDispatch();

    const isEPayPayment= policy && policy.clientPayments && policy.clientPayments.findIndex(x => x.type === "ePay") > -1
    const handleClose = () => {
        setPaymentReceivedPopUp(false);
        refreshList();
    };
    React.useEffect(() => {
        if(auth && auth.user && (auth.user.role <= 2 || auth.user.role === 12) ) {
            getTranches();
        }
    }, [])

    const getTranches = async () => {
        let payload:any = []
        const response: any = await trancheActions.listUpcomingTranche(listTrancheCount);
            response.map((tranche:any)=>{
            payload.push({
                id: tranche.trancheNumber,
                value: tranche.id,
                name: tranche.trancheNumber
            });
        });
        setTranche(payload);
    }

    const initialValues = {
        fundingType: client && client.payment_method,
        receivedAmount: client && client.issuedContribution,
        trancheNo: client && client.tranche && client.tranche.trancheNumber
    };
    const validate = ((value: any) => {
        const errors: { fundingType?: string, receivedAmount?: string, trancheNo?:string } = {};
        if (!value.fundingType) {
            errors.fundingType = "Required";
        }
        if (!value.receivedAmount || value.receivedAmount < 1) {
            errors.receivedAmount = "Required";
        }
        if (!value.trancheNo || tranche.findIndex((vl: any) => vl.id === value.trancheNo) === -1)  {
            errors.trancheNo = "Required";
        }
        return errors;
    });
    let fundingType: any[] =[];
    if(!isEPayPayment){
    fundingType = [
            {
                id: "personal_check",
                value: "personal_check",
                name: "Personal Check",
            },
            {
                id: "wire_transfer",
                value: "wire_transfer",
                name: "Wire Transfer",
            },
        ];
    }
    fundingType.push({
        id: "ePay",
        value: "ePay",
        name: "ePay",
    })
    const policyRow = policy && policy.clientPayments && policy.clientPayments.filter(payment => !!(payment.amount)).length > 0;
    const fieldObj = [
        {
            name: "fundingType",
            component: SelectField,
            className: "SelectFieldWidth",
            label: <Label label={"Funding Type"} required={true} />,
            variant: "outlined",
            options: fundingType,
            isRequired: true,
        },
        {
            id:"receivedAmount",
            name: "receivedAmount",
            label: <Label label={"Enter Received Amount"} required={true}/>,
            isRequired: true,
            component: NumberTextField,
            variant: "outlined",
            disabled: false,
        },
        {
            id: "trancheNo",
            name: "trancheNo",
            component: SelectField,
            className: "SelectFieldWidth",
            label: <Label label={"Closed Tranche No"} required={true} />,
            variant: "outlined",
            options: tranche,
            isRequired: true,
            disabled:(values) => {
                return ((values["fundingType"] === "ePay") && !!policyRow) || isEPayPayment
            }
        },
    ];

    const dialogProps: GenericDialogProps = {
        'aria-labelledby': "Enter-Payment-Received-Amount-dialog-title",
        fullWidth: true,
        maxWidth: "sm",
        content: (<>
                <Typography variant="body1" className="f18" align="center">
                    Enter the initial contribution amount that has been received for reporting purposes.
                </Typography>


    <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validate={values => validate(values)}
            validateOnChange={true}
            onSubmit={async (values, { setSubmitting }) => {
                let selectedTranche:any = tranche.find((val:any) => val.id === values.trancheNo);
                let trancheClosed:number|null = Number(selectedTranche && selectedTranche.id) || null;
                let trancheNo:number|null = Number(selectedTranche && selectedTranche.value) || null;
                let receivedAmount:number = Number(unmaskCurrency(values.receivedAmount.toString()));

                const res =  await accountActions.markPaymentReceived({
                    receivedAmount: receivedAmount,
                    agentId,
                    clientId,
                    enrollmentId,
                    trancheClosed,
                    trancheNo
                });

                if (res !== false){
                    await processPayment({
                        type: PaymentProcess.paymentReceived,
                        userId: client.userId,
                    })
                }
                await clientActions.getClient(clientId);
                if(setFilterType && role !== 4) {
                    setFilterType(CurrentActionsItems.MARK_PAYMENT_PROCESSED.actionType);
                    setPaymentReceivedPopUp(false);
                } else {
                    handleClose();
                }
            }}
    >
        {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleBlur,
              handleSubmit,
              isSubmitting,
              dirty,
          }) => (<form onSubmit={handleSubmit}>
            <Grid container spacing={2} className="mt15 mb20">
                {fieldObj.map((field:any, index) => {
                    return (
                            <Grid item xs={12} sm={6}>
                                <TextFieldCommon
                                        className="dollerIcon"
                                        {...field}
                                        disabled={(typeof field.disabled == "function") ? field.disabled(values) : field.disabled  }
                                        values={values}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                />
                                <ErrorCommon errors={errors} name={field.name}
                                             touched={touched}/>
                                {field.name === 'receivedAmount' &&
                                        <Typography variant='body1' align="left" className="f18">
                                            Amount due:&nbsp;
                                            <NumberFormat
                                                    value={(client && client.issuedContribution) || 0}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    prefix={"$"}/>
                                        </Typography>
                                }
                            </Grid>
                    );
                })
                }
            </Grid>
            <FormikButton
                    fullWidth={false}
                    type="submit"
                    label="Mark Payment Received"
                    variant="contained"
                    className="floatRight"
                    color="primary"
                    disabled={isSubmitting}
                    loading={isSubmitting}
            />
        </form>)}

                </Formik>
        </>),
        dividers: true,
        onClose: handleClose,
        open: open,
        title: 'Enter Payment Received Amount'
    };

    return (
            <>
                <GenericDialog {...dialogProps} />
            </>
    );
}