import React from "react";
import { Label } from "../../../../../components/formInputs";
import {
    ErrorCommon,
    TextFieldCommon,
    DatePicker, NumberTextField,
} from "../../../../../components/formikFormInputs";
import { Grid, Typography } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { restrictToTwoDecimals } from "../../../../../utils/commonUtils";

interface DistributionsProps {
    values:any, handleChange:Function, errors:any, touched:any, setFormError: Function, setDistributionFormError:Function, setLoanPayoffDateError:Function, disabled:boolean
}

const Distributions = (props: DistributionsProps) => {
    const { values, handleChange, errors, touched, setFormError, setDistributionFormError, setLoanPayoffDateError, disabled = false } = props;

    const fieldObj = [
        {
            name: "loanPayOffDate",
            label: <Label label={"Loan Payoff Date"}/>,
            isRequired: true,
            placeholder: "Loan Payoff Date",
            id: "loanPayoffDate",
            component: DatePicker,
            sizeMd: 6,
            className: "fullWidthcustomFields",
            errCb: (err:any) =>{
                if(err || err.length > 0){
                    setLoanPayoffDateError(true);
                } else {
                    setLoanPayoffDateError(false);
                }
            },
            disabled
        },
        {
            name: "startYearOfDeathBenefitReductions",
            label: <Label label={"Start Year of Death Benefit Reductions"} />,
            isRequired: true,
            placeholder: "Issue Date",
            id: "startYearOfDeathBenefitReductions",
            component: DatePicker,
            sizeMd: 6,
            className: "fullWidthcustomFields",
            errCb: (err:any) =>{
                if(err || err.length > 0){
                    setDistributionFormError(true);
                } else {
                    setDistributionFormError(false);
                }
            },
            disabled
        },
        {
            name: "incomeStartDate",
            label: <Label label={"Income Start Date"} />,
            isRequired: true,
            placeholder: "Income Start Date",
            id: "incomeStartDate",
            component: DatePicker,
            sizeMd:6,
            className: "fullWidthcustomFields",
            errCb: (err:any) =>{
                if(err){
                    setFormError(true);
                } else {
                    setFormError(false);
                }
            },
            disabled
        },
        {
            id: "loanPayOffAmount",
            name: "loanPayOffAmount",
            label: <Label label={"Loan Payoff Amount"}/>,
            component: NumberTextField,
            variant: "outlined",
            filedType: "number",
            className: "fullWidthcustomFields",
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon"
                                                position="start">$</InputAdornment>,
            },
            disabled
        },
    ];

    return (
            <React.Fragment>
                <Typography variant="subtitle1" component="strong" className="mb10 mt30 floatLeft w100">Distributions</Typography>
                <Grid container spacing={3}>
                    {fieldObj.map((field, index) => {
                        return (
                                <Grid item xs={12} md={6} xl={4}
                                      key={index}>
                                    <TextFieldCommon
                                            {...field}
                                            values={values}
                                            onChange={(e) => {
                                                restrictToTwoDecimals(e);
                                                handleChange(e);
                                            }}
                                    />
                                    <ErrorCommon errors={errors} name={field.name}
                                                 touched={touched}/>
                                </Grid>
                        );
                    })
                    }
                </Grid>
            </React.Fragment>
    );

};
export default Distributions;