import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
// import DocusignLoader from "../../../../../components/DocusignLoader";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
// import DocusignLoader from "../../../components/DocusignLoader";
import { noop } from "../../utils/commonUtils";
import DocusignLoader from "../DocusignLoader";
const useStyles = makeStyles(theme => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    }
}));
type pType = {
    documentUrl: string,
    openPdf:boolean,
    setOpenPdf: Function,
    inProgress:boolean,
    title?: string
    onDocSignComplete?: Function
    onClose?: Function
    onApprove?: Function
}
const DocuSign = (props: pType) => {
    const { openPdf, setOpenPdf, documentUrl, inProgress, onDocSignComplete, title , onClose, onApprove } = props;
    const classes = useStyles();

    return (
            <Dialog
                    onClose={()=>{
                        onClose &&  onClose()
                    }}
                    fullWidth={true}
                    maxWidth={"lg"}
                    open={openPdf}
                    aria-labelledby="Enrollment Home"
                    aria-describedby="Enrollment process"
            >
                <IconButton aria-label="close" className={classes.closeButton} onClick={() => {
                    setOpenPdf(false);
                }}>
                    <CloseIcon/>
                </IconButton>
                <DialogTitle className="dialogHead">
                    {title}
                    <Typography variant="h5" align='center'>
                    </Typography>
                </DialogTitle>
                <DialogContent className="pr30 pl30">
                    <div id="alert-dialog-description" className="textCenter" style={{height: "80vh"}}>
                        {inProgress ?
                                <Grid container justify="center" spacing={2}>
                                    <Grid item xs={12} md={12} className="textCenter">
                                        <DocusignLoader/>
                                    </Grid>
                                </Grid>
                                :
                                <iframe
                                        className={"enrollmentIframe"}
                                        src={`${documentUrl}`}
                                        onLoad={noop}
                                        style={{width: "100%", height: "80vh"}}
                                        title="Docusign"
                                />
                        }
                    </div>
                </DialogContent>
                <DialogActions  style={{display: onApprove ?  undefined : "none"}}>
                    <Button id={"backbtn"} style={{display: "none"}} color="primary" variant="contained"
                            className="mb20 mr30 mt15"
                            onClick={() => {
                                onDocSignComplete && onDocSignComplete()
                                setOpenPdf(false);
                            }}>
                        Close
                    </Button>
                    {<Button id={"approve-button"} color="primary" variant="contained"
                             className="mb20 mr30 mt15"
                             onClick={() => {
                                 onApprove && onApprove();
                             }}>
                        Approve
                    </Button>}
                </DialogActions>
            </Dialog>
    );
};

export default DocuSign;