import { allocationMapper } from "../utils/utils";
import { useActions } from "../../../../actions";
import * as Actions from "../../../../actions/estimation";
import * as React from "react";
import { useState } from "react";
import { isDebug } from "../../../../utils/commonUtils";
import * as EstimatorActions from "../../../../actions/estimator";
import { useSelector } from "react-redux";
import { RootState } from "../../../../reducers";
import * as CActions from "../../../../actions/client";


// estimatorId is null during the invoke of hoot then need to pass run time parameter in returning function
export function useCreateAllocationPayLoad(estimatorId : null| string, externalCarrierId?:any, clientId?:number){
    const estimationActions = useActions(Actions);
    const estimatorActions = useActions(EstimatorActions);
    const clientActions = useActions(CActions);
    const [allocationQuestion, setAllocationQuestion] = useState({});
    const [clientAllocations, setClientAllocations] = React.useState({});
    let { user: { client, accessType }, user } =  useSelector((state: RootState) => state.auth);
    const carrierId = user.accessType === "client" ? user.demoCarrierId : user.client && user.client.carrier && user.client.carrier.id;
    const strategyId = user.accessType === "client" ? user && user.demoStrategy && user.demoStrategy.id : user && user.client && user.client.strategy && user.client.strategy.id

    React.useEffect(()=>{
       (async ()=>{
           if(estimatorId){
               const res = await estimationActions.getAllocationQuestions({ estimatorId: estimatorId, carrierId: externalCarrierId ||  carrierId });
               const data = {};
               if (res && res.length > 0) {
                   res.forEach((fields) => {
                       data[fields.code] = fields;
                   });
               }
               setAllocationQuestion(data)
           }
           // no need to run on demo mode
           // (user.client && user.client.id) to use on client estimator
           // clientId to use on final estimator in admin side
           if(user.accessType !== "client" && ((user.client && user.client.id) || clientId)){
               // fetching client allocations assigned while invitation
               const response = await clientActions.getClientAllocations({clientId:(user.client && user.client.id || clientId)});
               if(response && Array.isArray(response)){
                   const data = {};
                   response.forEach((clientAllocation)=>{
                       if(clientAllocation.percentage){
                           data[clientAllocation.questionCode] = clientAllocation
                       }
                   });
                   setClientAllocations(data)
               }
               // fetching client allocations assigned while invitation
           }
       })()
   },[estimatorId, clientId])

    // use it on places of allocationMapper[InvestmentType]
    const getAllocationQuestionForClient = (code) =>{
        // if client made through new invitation and allocations exist in db
        if(clientAllocations && Object.keys(clientAllocations).length > 0){
            return Object.keys(clientAllocations)
        }
        // for old client it will work as before
        return  allocationMapper[code] || []
    }

    const getWeight = (code) => {
        if (clientAllocations[code]) {
            return clientAllocations[code].percentage;
        }
        return false;
    };
    // need to pass query to fetch estimator settings then to fetch allocations
    // one of query or (InitEstimatorId, runTimeCarrierId) needed if  estimatorId id is not define
   //  InitEstimatorId is estimator admin setting id
   return async (InvestmentType, options:{query?:any, InitEstimatorId?: any, runTimeCarrierId?:any} = {query:false, InitEstimatorId:null, runTimeCarrierId:null} ) =>{
       let allocationQuestionList = {...allocationQuestion };
       let { query = false , InitEstimatorId, runTimeCarrierId  } = options
            if(!estimatorId){
                let res
               if(!InitEstimatorId) {
                    if (!query) {
                        query = {
                            strategyId: strategyId,
                            carrierId: carrierId,
                        };
                    }
                    // if admin estimator setting does not exist need to fetch
                    const estimatorSettings = await estimatorActions.getEstimatorDetails(query);
                    res = await estimationActions.getAllocationQuestions({ estimatorId: estimatorSettings.id, carrierId: runTimeCarrierId || carrierId });
                }else{
                   res = await estimationActions.getAllocationQuestions({ estimatorId: InitEstimatorId, carrierId: runTimeCarrierId || carrierId });
               }
              isDebug() && console.log(res, " === res")
              const data = {};
              if (res && res.length > 0) {
                  res.forEach((fields) => {
                      data[fields.code] = fields;
                  });
              }
              allocationQuestionList = data
          }
        if(!getAllocationQuestionForClient(InvestmentType)) return [];
        const allocationweight= 100/getAllocationQuestionForClient(InvestmentType).length
        const allocationPayload = getAllocationQuestionForClient(InvestmentType).map((code)=>{
            return {
                "allocationname": code,
                "allocationrate": allocationQuestionList[code] && allocationQuestionList[code].percentage,
                "allocationweight": getWeight(code) || allocationweight,
            }
        })
        return [{
            "allocations":allocationPayload,
            "from": "1",
            "through": "M"
        }]
    }
}